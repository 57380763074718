/* You can add global styles to this file, and also import other style files */
/* @import './material/style-overrides/angular-material.scss'; */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

body {
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.lhc-item-question {
    align-items: center !important;
}
.lhc-tem {
    align-items: center !important;
}

.lhc-item-question, .lhc-de {
    align-items: center !important;
}

.autocomp_selected {
    border: 0px !important;
    min-width: 100% !important;
}

input {
    min-width: 100%;
    background-image: none !important;
}

.help-button, .copyright-button {
    background-color: transparent;
    border: none;
    padding: 0;
    color: #02a590 !important;

    .mat-icon {
        color: inherit;
        font-size: 16px;
    }
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #02a590 !important;
}

.mat-radio-button {
    margin-left: 5px
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, 
.mat-radio-button.mat-accent .mat-radio-ripple 
.mat-ripple-element:not(.mat-radio-persistent-ripple), 
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, 
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #02a590 !important;
}
/* custom styling input on ant-radio button */
.ant-radio-checked .ant-radio-inner {
    border-color: #02a590 !important;
  }
  
  .ant-radio-checked .ant-radio-inner::after {
    background-color: #02a590 !important;
  }
  
  .ant-radio-wrapper:hover .ant-radio-inner {
    border-color: #02a590 !important;
  }
  
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 2px rgba(2, 165, 144, 0.2) !important;
  }
  
  .ant-radio-disabled .ant-radio-inner {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
  }
  
  .ant-radio-disabled .ant-radio-inner::after {
    background-color: #d9d9d9 !important;
  }
  
  .ant-radio-disabled .ant-radio-wrapper:hover .ant-radio-inner,
  .ant-radio-disabled .ant-radio:hover .ant-radio-inner,
  .ant-radio-disabled .ant-radio-input:focus + .ant-radio-inner {
    border-color: #d9d9d9 !important; 
  }
  
  .ant-radio-wrapper:focus-within .ant-radio-inner {
    box-shadow: 0 0 0 3px rgba(2, 165, 144, 0.2) !important;
  }
  
/* styling of autocomplete dropdown results */
#searchResults {
    color: black !important;
    border: 1px solid;
    border-radius: 4px;
    width: 180px !important;
}
  .auto_complete > ul > li {
    font-size: 14px !important;
    padding: 10px 15px !important; 
    cursor: pointer !important; 
    color: rgba(0, 0, 0, 0.87) !important; 
    font-family: 'Roboto', 'Helvetica Neue', sans-serif !important; 
}

/* calendar styling  */
/* Custom styles for nz-datetime-picker */
.ant-picker-focused, .ant-picker:hover {
    border-color: #02a590  !important;
}
.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: #02a590 !important;
}
.ant-picker-calendar-date-today .ant-picker-cell-inner {
    border: 1px solid #02a590 !important; 
  }

.ant-picker-cell-today .ant-picker-cell-inner {
    border: 1px solid #02a590; 
  }

.listNum {
    color: #02a590 !important;
}

.auto_complete > ul > li:hover {
    background-color: #e0e0e0;

}
/* styling for headers */
.lhc-layout-horizontal .lhc-form-horizontal-table-title {
    border-radius: 4px;
    background-color: #d4e7c6;
    padding: 4px 2px 4px 2px;
  }
  
  .lhc-form .lhc-item.lhc-datatype-TITLE .lhc-item-display { 
    border-radius: 4px;
    background-color: #d4e7c6;
    padding: 4px 2px 4px 2px;
  }

  .lhc-layout-horizontal .lhc-form-horizontal-table-title, .lhc-form .lhc-item.lhc-datatype-TITLE .lhc-item-display, .lhc-form .lhc-item-group-title {
    margin-bottom: 5px;
  }  
  
  .lhc-form .lhc-item-group-title {
    border-radius: 4px;
    background-color: #d4e7c6;
    padding: 4px 2px 4px 2px;
  }

  .lhc-de-input-unit ng-star-inserted {
    margin-bottom: 5px !important;
  }
/* multiple answers chip styling  */
.autocomp_selected li {
    display: inline-flex !important;
    align-items: center !important;
    border: none !important;
    background-color: #e0e0e0; 
    color: rgba(0,0,0,.87) !important; 
    margin-right: 0.25em !important;
    margin-bottom: 0.25em !important;
    border-radius: 12px !important; 
    white-space: nowrap;
    cursor: default; 
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.2), 0px 2px 1px -1px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
/* remove botton padding from this element */
.mat-form-field-wrapper {
    padding-bottom: inherit !important;
}

.lhc-layout-horizontal .lhc-form-horizontal-table-title, .lhc-form .lhc-item.lhc-datatype-TITLE .lhc-item-display, .lhc-form .lhc-item-group-title {
  margin-bottom: 5px;
}

/* create a bit of spacing between row elements */
lhc-unit, .lhc-de-unit {
    margin-left: 4px;
}
/* Override the checkbox styling for ant-checkbox when it's checked */
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #02a590 !important;
    border-color: #02a590 !important;
  }
  
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #02a590 !important;
  }
  
  .ant-checkbox-checked::after {
    border-color: #02a590 !important;
  }
  
.autocomp_selected li > button:hover {
    background-color: #999 !important;
    border-radius: 15px !important;
}

.autocomp_selected li .remove-icon {
    margin-left: 8px !important;
    cursor: pointer !;
}
